<template>
<div>
  <b-form inline v-if="!ext_mode">
    <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header
                       placeholder="no date" size="sm"
                       :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
                       @input="refresh">
    </b-form-datepicker>
    
    <b-input-group prepend="Пилот" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm"
                       @input="refresh">
          <template #first>
            <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    
    <b-input-group prepend="Событие" class="mx-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="event_type" v-bind:options="event_types" size="sm"> </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>

  <b-card v-if="ext_mode">
    <b-row>
      <b-col>
        <b-form-group>
          <b-form-datepicker class="mx-2" v-model="date" locale="ru" hide-header
                             placeholder="no date" size="sm"
                             :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <b-form-datepicker class="mx-2" v-model="date2" locale="ru" hide-header
                             placeholder="no date" size="sm"
                             :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <b-form-select v-model="user_id" v-bind:options="users" text-field="name" value-field="id" size="sm">
            <template #first>
              <b-form-select-option :value="null">--  Все  --</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Система" label-size="sm">
          <system-selector v-model="system"></system-selector>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Радиус" label-size="sm">
          <b-input-group>
            <b-form-input v-model="radius" size="sm" type="number"> </b-form-input>
            <b-input-group-append>
              <b-form-radio-group v-model="radiusmode" size="sm" buttons  button-variant="outline-secondary">
                <b-form-radio value="in">в</b-form-radio>
                <b-form-radio value="out">вне</b-form-radio>
              </b-form-radio-group>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Тип тела" label-size="sm">
          <b-form-select v-model="subtype">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="Earth-like">Землеподобная</b-form-select-option>
            <b-form-select-option value="Water">Водная</b-form-select-option>
            <b-form-select-option value="Ammonia">Амиачная</b-form-select-option>
            <b-form-select-option value="High metal">С высоким содержанием металлов</b-form-select-option>
            <b-form-select-option value="Metal-rich">Богатая металлом</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Сигнал">
          <b-form-select v-model="body_signal_type" :options="body_signals">
            <template #first>
              <b-form-select-option :value="null">Не важно</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Станция" label-size="sm">
          <b-form-input v-model="station" size="sm" > </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
          <b-form-group label="Службы" label-size="sm">
            <b-form-checkbox-group v-model="services">
              <b-form-checkbox value="shipyard" size="sm">Верфь</b-form-checkbox>
              <b-form-checkbox value="outfitting" size="sm">Снаряжение</b-form-checkbox>
              <b-form-checkbox value="materialtrader" size="sm">Меняла</b-form-checkbox>
              <b-form-checkbox value="techBroker" size="sm">Техноброкер</b-form-checkbox>
              <b-form-checkbox value="facilitator" size="sm">Юрист</b-form-checkbox>
              <b-form-checkbox value="blackmarket" size="sm">Черный рынок</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
      </b-col>
    </b-row>
    <b-button variant="outline-success" @click="refresh">Поиск</b-button>
  </b-card>
  
  <b-form-checkbox class="mx-2" v-model="ext_mode" switch size="sm">Расширенный </b-form-checkbox>
  
  <div class="mt-2">
    <small>
    <b-table :items="flightlog_filtered" :fields="flightlog_fields" 
             bordered head-variant="dark" small striped responsive hover>
      <template #cell(system)="row">
        <b-link v-if="row.item.system_id" :to="`/system/${row.item.system_id}`">{{row.item.system}}</b-link>
        <div v-else>{{row.item.system}}</div>
        <b-link v-if="row.item.faction_id" :to="`/faction/${row.item.faction_id}`" class="float-right text-muted">
          {{ row.item.faction }}
        </b-link>
      </template>
      <template #cell(body)="row">
        <b-link v-if="row.item.station_id" :to="`/station/${row.item.station_id}`">{{ row.item.body }}</b-link>
        <div v-else>{{ row.item.body }}</div>
      </template>
    </b-table>
    </small>
  </div>
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';
import SystemSelector from '@/components/SystemSelector.vue';

moment.locale('ru');

export default {
    name: 'CmdrFlightLog',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
    },
    data() {
        return {
            date: moment().format('YYYY-MM-DD'),
            myonly: true,
            user_id: this.getUserInfo().id,
            users: [],
            event_type: null,
            event_types: [{ value:null, text:'Все' },
                          { value:'FSDJump', text:'Прыжок' },
                          { value:'SupercruiseExit', text:'Выход из гипера' },
                          { value:'Docked', text:'Стыковка' },
                         ],
            flightlog: [],
            flightlog_fields: [
                { key: 'username', label: 'Пилот', sortable: true, thStyle: { "min-width":"150px" }, },
                { key: 'ship', label: 'Корабль', sortable: true, thStyle: { "min-width":"300px" } },
                { key: 'time_stamp', label: 'Время', sortable: true, thStyle: { "min-width":"75px" },
                  formatter: value => {
                      if (this.ext_mode) {
                        return moment.unix(value).format("DD.MM HH:mm");
                      } else {
                        return moment.unix(value).format("HH:mm");
                      }
                  }
                },
                { key: 'event', label: 'Событие', sortable: true, thStyle: { "min-width":"150px" },
                  formatter: value => {
                      if (value == 'FSDJump') return 'Прыжок'
                      if (value == 'SupercruiseExit') return 'Выход из гипера'
                      if (value == 'Docked') return 'Стыковка'
                      return value;
                  },
                },
                { key: 'power', label: 'Сила', sortable: true, thStyle: { "min-width":"200px" } },
                { key: 'system', label: 'Система', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'body', label: 'Тело', sortable: true, thStyle: {"min-width":"200px"}  },
                { key: 'body_type', label: 'Тип' },
                { key: 'jump_dist', label: 'Прыг', thStyle: { width:"75px" }, class: "text-right" },
                { key: 'distance', label: 'Дист', thStyle: { width:"75px" }, class: "text-right" },
            ],
            ext_mode: false,
            date2: null,
            system: null,
            radius: null,
            radiusmode: 'in',
            station: null,
            services: [],
            subtype: null,
            body_signal_type: null,
            body_signals: [
                { value: 'geological', text: 'Геологический' },
                { value: 'biological', text: 'Биологический' },
                { value: 'human', text: 'Человеки' },
                { value: 'thargoid', text: 'Таргоиды' },
                { value: 'guardian', text: 'Стражи' },
            ],
        }
    },
    computed: {
        flightlog_filtered() {
            return this.flightlog.filter(item => {
                return ((this.event_type === null || item.event == this.event_type));
            });
        },
    },
    async mounted() {
        this.$on('refresh', this.refresh);
        this.users = await WS.request('users');
    },
    methods: {
        async refresh() {
            try {
                if (this.date) {
                    if (this.ext_mode) {
                        this.flightlog = await WS.request('xflightlog',
                                                          { user: this.user_id,
                                                            date1: this.date,
                                                            date2: this.date2,
                                                            system: this.system,
                                                            radius: this.radius,
                                                            radiusmode: this.radiusmode,
                                                            subtype: this.subtype,
                                                            body_signal_type: this.body_signal_type,
                                                            station: this.station,
                                                            services: this.services
                                                          });
                    } else {
                        this.flightlog = await WS.request('flightlog',
                                                          { date: this.date, user_id: this.user_id });
                    }
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
    }
}
</script>

<style>
.systemstable {
    /* overflow-y: scroll; */
    max-height: calc(100vh - 250px);
}

</style>
