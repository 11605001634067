<template>
<div class="p-2">
  <b-card>
    <div v-if="!short">
      <b-row>
        <b-col>Название:</b-col>
        <b-col>{{station_data.name}}</b-col>
        <b-col>Система:</b-col>
        <b-col>
          <b-link :to="`/system/${station_data.system_id}`">{{station_data.system}}</b-link>
        </b-col>
        <b-col>Расстояние до звезды:</b-col>
        <b-col>{{station_data.distance_to_star}}</b-col>
      </b-row>
      <b-row>
        <b-col>Контролирующая фракция:</b-col>
        <b-col>
          <b-link :to="`/faction/${station_data.faction_id}`">{{station_data.faction}}</b-link>
        </b-col>
        <b-col>Макс.площадка:</b-col>
        <b-col>{{station_data.max_landing_pad_size}}</b-col>
        <b-col>Обновлено:</b-col>
        <b-col> {{ station_data.updated_at | timestamp }} </b-col>
      </b-row>
      <b-row>
        <b-col>Экономика:</b-col>
        <b-col>{{station_data.economies}}</b-col>
        <b-col>Тип:</b-col>
        <b-col>
          {{station_data.type}} <span v-if="station_data.is_planetary"> (П) </span>
        </b-col>
        <b-col>Рынок обновлен:</b-col>
        <b-col> {{ station_data.market_updated_at | timestamp }} </b-col>
      </b-row>
      <b-row>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col>Верфь обновлена:</b-col>
        <b-col> {{ station_data.shipyard_updated_at | timestamp }} </b-col>
      </b-row>
    </div>
    
    <b-row>
      <b-col cols="2"> Импорт </b-col>
      <b-col> {{ station_data.imported }} </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"> Экспорт </b-col>
      <b-col> {{ station_data.exported }} </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"> Запрещенные </b-col>
      <b-col> {{ station_data.prohibited }} </b-col>
    </b-row>
    <b-row>
      <b-col cols="2"> Службы </b-col>
      <b-col>{{ station_data.services }} </b-col>
    </b-row>
  </b-card>

  <b-form inline class="m-2">
    <b-input-group prepend="Сервис" class="mr-2" size="sm">
      <b-input-group-append>
        <b-form-select v-model="service" size="sm">
          <b-form-select-option value="market">Рынок товаров</b-form-select-option>
          <b-form-select-option value="shipyard">Верфь</b-form-select-option>
          <b-form-select-option value="outfitting">Снаряжение</b-form-select-option>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>    
    <b-input-group prepend="Категория" class="mr-2" size="sm" v-show="service == 'market'">
      <b-input-group-append>
        <b-form-select v-model="category" v-bind:options="categories" size="sm"
                       text-field="name" value-field="name">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
    <b-input-group prepend="Название" class="mx-2" size="sm" v-show="service == 'market'">
      <b-form-input v-model="search" size="sm"></b-form-input>
    </b-input-group>
    <b-input-group prepend="Категория" class="mr-2" size="sm" v-show="service == 'outfitting'">
      <b-input-group-append>
        <b-form-select v-model="module_category" v-bind:options="module_categories" size="sm">
          <template #first>
            <b-form-select-option :value="null">--  Любая  --</b-form-select-option>
          </template>
        </b-form-select>
      </b-input-group-append>
    </b-input-group>
  </b-form>
  
  <div v-if="service == 'market'">
    <b-table :items="commodities_filtered" :fields="commodities_fields" 
             bordered head-variant="dark" small striped responsive hover 
             :tbody-tr-class="expansRowClass" thead-tr-class="mytablehead" thead-class="mytablehead">
    </b-table>
  </div>
  <div v-if="service == 'shipyard'">
    <b-table :items="shipyard_filtered" :fields="shipyard_fields" 
             bordered head-variant="dark" small striped responsive hover >
    </b-table>
  </div>
  <div v-if="service == 'outfitting'">
    <b-table :items="outfitting_filtered" :fields="outfitting_fields" 
             bordered head-variant="dark" small striped responsive hover >
    </b-table>
  </div>
  
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'StationView',
    inject: ['getUserInfo'],
    data() {
        return {
            station_id: null,
            service: 'market',
            search: '',
            category: null,
            categories: [],
            station_data: { imported: [], exported: [], prohibited: [], services: [], commodities: [],
                            shipyard: [], outfitting: [] },
            commodities_fields: [
                { key: 'name', label: 'Название', sortable: true, },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'buy_price', label: 'Покупка', sortable: true,
                  class: "text-right", thStyle: { width:"100px" }
                },
                { key: 'buy_diff', label: 'Профит', sortable: true,
                  class: "text-right", thStyle: { width:"100px" },
                  tdClass: (value) => {
                      if (value > 0) { return 'table-success' }
                      if (value < 0) { return 'table-danger' }
                  }
                },
                { key: 'sell_price', label: 'Продажа', sortable: true,
                  class: "text-right", thStyle: { width:"100px" }
                },
                { key: 'sell_diff', label: 'Профит', sortable: true,
                  class: "text-right", thStyle: { width:"100px" },
                  tdClass: (value) => {
                      if (value > 0) { return 'table-success' }
                      if (value < 0) { return 'table-danger' }
                  }
                },
                { key: 'mean_price', label: 'Средняя',
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'stock', label: 'Предложение', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
                { key: 'demand', label: 'Спрос', sortable: true,
                  class: "text-right", thStyle: {width:"100px"}
                },
            ],
            outfitting_fields: [
                { key: 'name', label: 'Наименование', sortable: true },
                { key: 'category', label: 'Категория', sortable: true },
                { key: 'mount', label: 'Монтаж' },
                { key: 'class', label: 'Класс', sortable: true, thStyle: {width:"50px"}, },
                { key: 'rating', label: 'Рейтинг', sortable: true, thStyle: {width:"50px"},  },
                { key: 'cost', label: 'Цена',
                  class: "text-right", thStyle: {width:"150px"}
                },
            ],
            shipyard_fields: [
                { key: 'name', label: 'Наименование', sortable: true },
                { key: 'basevalue', label: 'Базовая цена',
                  class: "text-right", thStyle: {width:"150px"}
                },
            ],
            module_category: null,
            module_categories: [
                { value: 'hardpoint', text: 'Оружейный отсек' },
                { value: 'utility', text: 'Внешний подвес' },
                { value: 'standard', text: 'Основной отсек' },
                { value: 'internal', text: 'Дополнительный отсек' },
            ]
        }
    },
    computed: {
        commodities_filtered() {
            return this.station_data.commodities.filter(item => {
                return ((this.category === null || (item.category == this.category)) &&
                        (item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1));
            });
        },
        shipyard_filtered() {
            return this.station_data.shipyard;
        },
        outfitting_filtered() {
            return this.station_data.outfitting.filter(item => {
                return ((this.module_category === null || (item.category == this.module_category)));
            });
        },
    },
    watch: {
        async $route(to) {
            console.log('StationView routed',to);
            if (to.name == 'station') {
                if (to.params.station_id) {
                    this.station_id = to.params.station_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.station_id) {
            this.station_id = this.$route.params.station_id;
        }
        await this.refresh();
    },
    async deactivated() {
        this.station_data = {};
    },
    methods: {
        async refresh() {
            try {
                this.categories = await WS.request('commodity_categories');
                this.station_data = await WS.request('station_data', this.station_id);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.mytablehead {
    position: sticky;
    top: 0;
}
.maincontainer {
    height: 100vh;
}
.systemstable {
    /* overflow-y: scroll; */
    /* max-height: calc(100vh - 300px); */
}
</style>
