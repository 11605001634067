<template>
<div>
  <b-card no-body>
    <b-tabs card @activate-tab="refresher" v-model="activeTab">
      <b-tab title="Профиль">
        <b-card-group deck class="flex-wrap">
          <b-card v-for="user,idx in user_list" :key="idx" class="mb-2"
                  style="min-width: 24rem;max-width: 32rem;">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="mb-0">{{user.name}}</h6>
                <b-form-checkbox size="sm" v-model="user.frozen" @change="frozen(user)"> Заморожен </b-form-checkbox>
                <span>{{user.last_event | timestamp}}</span>
              </div>
            </template>
            <b-card-text>
              <b-row>
                <b-col cols="4" class="text-right">Кмдр:</b-col>
                <b-col>{{user.profile.name}}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4" class="text-right">Кредиты:</b-col>
                <b-col>{{user.profile.credits | num2text}}</b-col>
              </b-row>
              <b-row align-v="center">
                <b-col cols="4" class="text-right">Корабль:</b-col>
                <b-col>
                  <div v-if="user.ship.ship_id >= 0">
                    <b-button variant="info" @click="showLoadout(user)">
                      {{user.ship.type}}
                      <span v-show="user.ship.name"> "{{user.ship.name}}" </span>
                      <span v-show="user.ship.identifier"> ({{user.ship.identifier}}) </span>
                    </b-button>
                  </div>
                  <div v-else>
                    {{user.ship.type}}
                    <span v-show="user.ship.name"> "{{user.ship.name}}" </span>
                    <span v-show="user.ship.identifier"> ({{user.ship.identifier}}) </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" class="text-right">Система:</b-col>
                <b-col>
                  <div v-if="user.profile.system_id">
                    <b-link :to="`/system/${user.profile.system_id}`">{{user.profile.system}}</b-link>
                  </div>
                  <div v-else> {{user.profile.system}} </div>
                </b-col>
              </b-row>
              <b-row v-if="user.profile.starport">
                <b-col cols="4" class="text-right">Пристыкован:</b-col>
                <b-col>
                  <div v-if="user.profile.starport_id">
                    <b-link :to="`/station/${user.profile.starport_id}`">{{user.profile.starport}}</b-link>
                  </div>
                  <div v-else> {{user.profile.starport}} </div>
                </b-col>
              </b-row>
              <b-row v-if="user.fleetcarrier.name">
                <b-col cols="4" class="text-right">Авик:</b-col>
                <b-col>
                  <b-link :to="`/fc/${user.fleetcarrier.callsign}`">{{user.fleetcarrier.name}}</b-link>
                </b-col>
              </b-row>
            </b-card-text>
            <template #footer >
              <div class="d-flex justify-content-between align-items-center">
                <b-overlay :show="user.busy" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                  <b-button ref="jbutton" :disabled="user.busy" size="sm" variant="outline-primary" @click="getAll(user)">
                    Обновить
                  </b-button>
                </b-overlay>
                <b-button-group>
                  <b-button :to="`/statistics/${user.id}`" size="sm" class="border">Стат</b-button>
                  <b-button :to="`/materials/${user.id}`" size="sm" class="border">Материалы</b-button>
                  <b-button :to="`/fleet/${user.id}`" size="sm" class="border">Флот</b-button>
                  <b-button :to="`/modules/${user.id}`" size="sm" class="border">Модули</b-button>
                </b-button-group>
              </div>
            </template>

          </b-card>
        </b-card-group>
        
        <div class="mt-2">
          <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                     class="d-inline-block">
            <b-button ref="pbutton" :disabled="busy" class="mx-2" squared variant="primary" @click="getProfile">
              Обновить профиль
            </b-button>
            <b-button ref="jbutton" :disabled="busy" class="mx-2" squared variant="primary" @click="getJournal">
              Обновить журнал
            </b-button>
            <b-button ref="fcbutton" :disabled="busy" class="mx-2" squared variant="primary" @click="getMarket">
              Обновить рынок
            </b-button>
            <b-button ref="fcbutton" :disabled="busy" class="mx-2" squared variant="primary" @click="getFC">
              Обновить авик
            </b-button>
          </b-overlay>
        </div>
      </b-tab>
      
      <b-tab title="Миссии" >
        <keep-alive>
          <cmdr-missions ref="missions_ref"></cmdr-missions>
        </keep-alive>
      </b-tab>
      <b-tab title="Всякое" >
        <keep-alive>
          <cmdr-actions ref="actions_ref"></cmdr-actions>
        </keep-alive>
      </b-tab>
      <b-tab title="Торговля" >
        <keep-alive>
          <cmdr-trade ref="trade_ref"></cmdr-trade>
        </keep-alive>
      </b-tab>
      <b-tab title="БортЖурнал" >
        <keep-alive>
          <cmdr-flight-log ref="flightlog_ref"></cmdr-flight-log>
        </keep-alive>
      </b-tab>
    </b-tabs>
  </b-card>

  <b-modal id="squad_ship_loadout-modal" size="xl" hide-header ok-only>
    <ship-loadout :user_id="ship_user_id" :ship_id="ship_id"></ship-loadout>
  </b-modal>
  
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import CmdrMissions from '@/components/CmdrMissions.vue';
import CmdrActions from '@/components/CmdrActions.vue';
import CmdrTrade from '@/components/CmdrTrade.vue';
import CmdrFlightLog from '@/components/CmdrFlightLog.vue';
import ShipLoadout from "@/components/ShipLoadout.vue";
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CommanderSquad',
    inject: ['getUserInfo'],
    components: {
        CmdrMissions, CmdrActions, CmdrFlightLog, ShipLoadout, CmdrTrade, 
    },
    data() {
        return {
            activeTab: 0,
            busy: false,
            user_list: [],
            ship_user_id: null,
            ship_id: null,
        }
    },
    watch: {
        async $route() {
            await this.refresh();
        }
    },
    async mounted() {
        await this.getProfile();
    },
    methods: {
        async refresh() {
            try {
                this.user_list = await WS.request('users');
                this.user_list.forEach((item) => {
                    Vue.set(item,'busy', false);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async refreshOne(user) {
            try {
                let data = await WS.request('user', user.id);
                for (let key of ['last_event','profile','ship','fleetcarrier','frozen']) {
                  user[key] = data[key];
                }
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async getProfile() {
            this.busy = true
            try {
                await WS.request('profile');
            } catch (err) {
                this.$bvModal.msgBoxOk(`getProfile: ${err}`);
            }
            this.busy = false
            await this.refresh();
        },
        async getJournal() {
            this.busy = true
            try {
                var log;
                log = await WS.request('journal');
                this.$bvModal.msgBoxOk(`Загружено ${log.amount} журналов, ${log.systems} систем.`);
            } catch (err) {
                this.$bvModal.msgBoxOk(`Journal: ${err}`);
            }
            this.busy = false
        },
        async getFC() {
            this.busy = true
            try {
                await WS.request('fleetcarrier');
            } catch (err) {
                this.$bvModal.msgBoxOk(`getFC: ${err}`);
            }
            this.busy = false
            await this.refresh();
        },
        async getMarket() {
            this.busy = true
            try {
                await WS.request('market');
            } catch (err) {
                this.$bvModal.msgBoxOk(`getMarket: ${err}`);
            }
            this.busy = false
        },
        async getAll(user) {
            user.busy = true
            try {
                let log = await WS.request('everything', user.id);
                this.$bvModal.msgBoxOk(`Загружено ${log.amount} журналов, ${log.systems} систем.`);
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            }
            user.busy = false
            await this.refreshOne(user);
        },
        async refresher(newTabIndex, prevTabIndex) {
            if (prevTabIndex != -1) {
                if (newTabIndex == 0) {
                    await this.refresh();
                } else if (newTabIndex == 1) {
                    this.$refs.missions_ref.$emit('refresh');
                } else if (newTabIndex == 2) {
                    this.$refs.actions_ref.$emit('refresh');
                } else if (newTabIndex == 3) {
                    this.$refs.trade_ref.$emit('refresh');
                } else if (newTabIndex == 4) {
                    this.$refs.flightlog_ref.$emit('refresh');
                }
            }
        },
        async frozen(user) {
            let choice = await this.$bvModal.msgBoxConfirm('Вы уверены в своих действиях?', 
                                                            { size: 'sm', buttonSize: 'sm', okVariant: 'danger', 
                                                              okTitle: 'Да', cancelTitle: 'Нет', });
            if (choice) {
                try {
                    await WS.request('user_update', { user_id: user.id, frozen: user.frozen });
                } catch (err) {
                    this.$bvModal.msgBoxOk(`getFC: ${err}`);
                }
            } else {
              user.frozen = !user.frozen;
            }
        },
        showLoadout(user) {
            this.ship_user_id = user.id;
            this.ship_id = user.ship.ship_id;
            this.$bvModal.show("squad_ship_loadout-modal");
        },
    },
    filters: {
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return (value === null) ? '--' : value.toFixed(2);
            }
        },
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
    },
}
</script>

<style>
.cmdrcard {
    width: 300px;
}
</style>
