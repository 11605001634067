<template>
<div>
  <b-table :items="modules_filtered" :fields="modules_fields" 
           bordered head-variant="dark" striped responsive hover small>
    <template #cell(location)="row">
      <div v-if="row.item.ship_id !== null">
        <b-button size="sm" block variant="info" @click="showLoadout(row.item)">
          {{ row.item.ship_type }}
          <span v-show="row.item.ship_name"> "{{ row.item.ship_name }}" </span>
        </b-button>
      </div>
      <div v-else>
        <div v-if="row.item.system_id">
          <b-link :to="`/system/${row.item.system_id}`">{{ row.item.system_name }}</b-link>
        </div>
        <div v-else>{{ row.item.system_name }}</div>
        <span v-if="row.item.station_name" class="float-right text-muted">{{ row.item.station_name }}</span>
      </div>
    </template>
    <template #cell(name)="row">
      {{ row.item.name }}
      <span v-if="row.item.hot" class="float-right text-danger">H</span>
    </template>
    <template #cell(class)="row">
      {{ row.item.class }}{{ row.item.rating }}<span v-if="row.item.mount">-{{ row.item.mount }}</span>
    </template>
    <template #cell(blueprint)="row">
      {{ row.item.blueprint }}
      <span v-if="row.item.level" class="float-right text-muted">G{{ row.item.level }}</span>
      <div v-if="row.item.expeffect">
        <p class="float-right">{{ row.item.expeffect }}</p>
      </div>
    </template>
    <template #head(category)="data">
      {{ data.label }}
      <b-form-select v-model="module_category" v-bind:options="module_categories" size="sm">
        <template #first>
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
        </template>
      </b-form-select>
    </template>    
    <template #head(name)="data">
      {{ data.label }}
      <b-form-select v-model="module_name" v-bind:options="module_names_filtered" size="sm"
                     text-field="name" value-field="name">
        <template #first>
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
        </template>
      </b-form-select>
    </template>    
    <template #head(blueprint)="data">
      {{ data.label }}
      <b-form-select v-model="module_blueprint" v-bind:options="module_blueprints_filtered" size="sm"
                     text-field="name" value-field="name">
        <template #first>
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
        </template>
      </b-form-select>
    </template>
    <template #head(class)="data">
      {{ data.label }}
      <b-form-select v-model="module_class" v-bind:options="module_classes_filtered" size="sm"
                     text-field="name" value-field="name">
        <template #first>
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
        </template>
      </b-form-select>
    </template>    
  </b-table>
  <b-modal id="modules_ship_loadout-modal" size="xl" hide-header ok-only>
    <ship-loadout :user_id="user_id" :ship_id="ship_id"></ship-loadout>
  </b-modal>
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import ShipLoadout from "@/components/ShipLoadout.vue";

export default {
    name: 'CmdrModules',
    inject: ['getUserInfo'],
    components: {
        ShipLoadout,
    },
    data() {
        return {
            user_id: null,
            modules: [],
            modules_fields: [
                { key: 'category', label: 'Категория', sortable: true, thStyle: { width:"150px" } },
                { key: 'name', label: 'Название', sortable: true, thStyle: { "min-width":"300px" } },
                { key: 'class', label: 'Кл', sortable: true, thStyle: { "min-width":"70px" } },
                { key: 'blueprint', label: 'Чертеж', sortable: true, thStyle: { "min-width":"300px" }, },
                { key: 'location', label: 'Корабль/Станция', sortable: true, thStyle: { "min-width":"300px" } },
                { key: 'buyprice', label: 'Стоимость', class: "text-right", thStyle: { width:"100px" }, },
            ],
            ship_id: null,
            module_category: null,
            module_categories: [],
            module_name: null,
            module_names: [],
            module_blueprint: null,
            module_blueprints: [],
            module_class: null,
            module_classes: [],
        }
    },
    computed: {
        modules_filtered() {
            return this.modules.filter(item => {
                return ((this.module_category === null || item.category == this.module_category) &&
                        (this.module_name === null || item.name == this.module_name) &&
                        (this.module_blueprint === null || item.blueprint == this.module_blueprint) &&
                        (this.module_class === null || item.class == this.module_class)
                       );
            });
        },
        module_names_filtered() {
            return this.module_names.filter(item => {
                return ((this.module_category === null || item.category == this.module_category));
            });
        },
        module_blueprints_filtered() {
            return this.module_blueprints.filter(item => {
                return ((this.module_category === null || item.category.includes(this.module_category)) &&
                        (this.module_name === null || item.module.includes(this.module_name)));
            });
        },
        module_classes_filtered() {
            return this.module_classes.filter(item => {
                return ((this.module_category === null || item.category.includes(this.module_category)) &&
                        (this.module_name === null || item.module.includes(this.module_name)));
            });
        },
    },
    watch: {
        async $route(to) {
            console.log('CmdrModules routed',to)
            if (to.name == 'modules') {
                if (to.params.user_id) {
                    this.user_id = to.params.user_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.user_id) {
            this.user_id = this.$route.params.user_id;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.modules = await WS.request('modules', this.user_id);
                this.modules.forEach((item) => {
                    if (!this.module_categories.includes(item.category)) {
                        this.module_categories.push(item.category);
                    }
                    if (!this.module_names.find(xx => xx.name == item.name)) {
                        this.module_names.push({ category: item.category, name: item.name });
                    }
                    let blueprint = this.module_blueprints.find(xx => xx.name == item.blueprint)
                    if (!blueprint) {
                        this.module_blueprints.push({ category: [item.category],
                                                      module: [item.name], name: item.blueprint });
                    } else {
                        blueprint.module.push(item.name);
                        blueprint.category.push(item.category);
                    }
                    let mdclass = this.module_classes.find(xx => xx.name == item.class);
                    if (!mdclass) {
                        this.module_classes.push({ category: [item.category],
                                                   module:[item.name], name:item.class });
                    } else {
                        mdclass.module.push(item.name);
                        mdclass.category.push(item.category);
                    }
                    if (item.ship_id !== null) {
                        Vue.set(item,'location',item.ship_type);
                    } else {
                        Vue.set(item,'location',' '+item.system_name + item.station_name);
                    }
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        showLoadout(item) {
            this.ship_id = item.ship_id;
            this.$bvModal.show("modules_ship_loadout-modal");
        },
    },
    filters: {
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>

</style>
